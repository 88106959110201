import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import PricingDisplay from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingDisplay/component';
import withPricingData from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingData';
import { createSelector } from 'reselect';
import {
  makeSelectPricingDataDisplay
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { connect } from 'react-redux';
import {
  makeSelectGetExchangedCurrency,
  selectCurrentCurrency
} from '@rentivo/gatsby-core/src/containers/CurrencyProvider/selectors';

const mapStateToProps = createSelector(
  makeSelectPricingDataDisplay,
  makeSelectGetExchangedCurrency,
  selectCurrentCurrency,
  (display, getExchangedCurrency, currentCurrency) => ({
    display,
    getExchangedCurrency,
    currentCurrency
  })
);

const enhance = compose(
  withPricingData,
  connect(mapStateToProps)
);

PricingDisplay.defaultProps = {
  className: '',
  inCheckout: false
};

PricingDisplay.propTypes = {
  className: PropTypes.string,
};

export default enhance(PricingDisplay);
