import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PricingActions';

export default defineMessages({
  buttonInstantBookLabel: {
    id: `${scope}.buttonInstantBook.label`,
    defaultMessage: 'Book now',
  },
  buttonRequestToBookLabel: {
    id: `${scope}.buttonRequestToBook.label`,
    defaultMessage: 'Request to book',
  },
  buttonEnquireLabel: {
    id: `${scope}.buttonEnquireWithPrice.label`,
    defaultMessage: 'Make an inquiry',
  },
});
