import React from 'react';
import { compose } from 'redux';
import PricingWidget from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingWidget/component';
import withPricingData from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingData';
import { createSelector } from 'reselect';
import {
  makeSelectPricingDataDisplay,
  selectPricingDataErrors
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { connect } from 'react-redux';
import {
  BOOKABLE_TYPE_ENQUIRY_WITH_PRICE,
  BOOKABLE_TYPE_INSTANT_BOOKABLE,
  BOOKABLE_TYPE_REQUEST_TO_BOOK
} from '@rentivo/gatsby-core/src/constants/lycanConstants';
import {
  makeSelectCommercePropertyAndPricingInCart,
  selectBookableTypeFromPricingInCart
} from '@rentivo/gatsby-core/src/containers/CommerceProvider/selectors';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';


const mapStateToProps = createSelector(
  selectPricingDataErrors,
  makeSelectCommercePropertyAndPricingInCart,
  makeSelectPricingDataDisplay,
  (errors, { pricingInCart }, display) => {
    const bookableTypeInCart = selectBookableTypeFromPricingInCart(pricingInCart);
    return {
      display,
      errors,
      isCheckoutInstantBook: (bookableTypeInCart === BOOKABLE_TYPE_INSTANT_BOOKABLE),
      isCheckoutRequestToBook: (bookableTypeInCart === BOOKABLE_TYPE_REQUEST_TO_BOOK || bookableTypeInCart === BOOKABLE_TYPE_ENQUIRY_WITH_PRICE)
    };
  }
);

const enhance = compose(
  withPricingData,
  withPricingInputs,
  connect(mapStateToProps)
);

PricingWidget.defaultProps = {
  inCheckout: false,
  showHeader: true,
  components: {

  }
};

export default enhance(PricingWidget);
