import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPickerPopover/messages';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';
import Component from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPicker';
import { chakra, Tooltip, Box } from '@chakra-ui/react';
import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';

const GuestsPickerPopoverComponent = ({id, component, scrollTo, tooltip, tooltipPlacement, block, guestsPopoverOpen, setGuestsPopoverOpen, areDatesSelected, title, componentProps, guestsObj, styleProps = {}, ...rest}) => {
  const { formatMessage } = useIntl();
  const {
    guests,
    pets
  } = guestsObj || {};

  const areGuestsSelected = (guests > 0);

  return (
    <chakra.div {...styleProps}>
      {title !== undefined ? title : <Text as="label" display="block" size="sm" mb={2} color="textLight" fontWeight="medium"><FormattedMessage {...messages.title}/></Text>}
      <ConditionalWrapper
        condition={tooltip}
        wrapper={c =>
          <TouchTooltip
            isOpen={(areDatesSelected !== false && areGuestsSelected === false && !guestsPopoverOpen)}
            placement={tooltipPlacement}
            label={formatMessage(messages.prompt)}
          >
            <Box>
              {c}
            </Box>
          </TouchTooltip>
        }
      >
        <PopoverButton
          id={id}
          button={{
            block
          }}
          scrollTo={scrollTo}
          popoverWidth={319}
          component={component ? component : Component}
          componentProps={componentProps}
          visible={guestsPopoverOpen}
          onVisibleChange={() => setGuestsPopoverOpen(!guestsPopoverOpen)}
          buttonLabel={areGuestsSelected ? <FormattedMessage {...messages.setLabel} values={{guestsCount: guests, petsCount: pets}}/> : <FormattedMessage {...messages.label}/>}
        />
      </ConditionalWrapper>

    </chakra.div>
  );
};

export default GuestsPickerPopoverComponent;

