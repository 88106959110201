import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PropertyEnquiry';

export default defineMessages({
  okButtonLabel: {
    id: `${scope}.okButton.label`,
    defaultMessage: 'Send enquiry'
  },
  cancelButtonLabel: {
    id: `${scope}.cancelButton.label`,
    defaultMessage: 'Cancel'
  },
  buttonEnquireLabel: {
    id: `${scope}.buttonEnquireWithPrice.label`,
    defaultMessage: 'Make an inquiry',
  },
  buttonEnquireTitle: {
    id: `${scope}.buttonEnquireWithPrice.title`,
    defaultMessage: 'Make an inquiry',
  },
});
