import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PricingErrors';

export default defineMessages({
  unavailableTitle: {
    id: `${scope}.unavailable.title`,
    defaultMessage: 'This property is unavailable for your dates & guests.',
  },
});
