import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import GuestsPickerPopover from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPickerPopover/component';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';

const enhance = compose(
  withPricingInputs
);

GuestsPickerPopover.defaultProps = {
  id: null,
  datesSelected: false,
  //title: null,
  block: true,
  scrollTo: false,
  className: 'pricing-popover',
  component: null,
  componentProps: {
    onChange: () => null
  },
  tooltip: false,
  tooltipPlacement: 'top'
};

GuestsPickerPopover.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  block: PropTypes.bool,
  scrollTo: PropTypes.bool,
  tooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.element,
  componentProps: PropTypes.object
};

export default enhance(GuestsPickerPopover);
