import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.InputPromptButton';

export default defineMessages({
  guestsPrompt: {
    id: `${scope}.guests.prompt`,
    defaultMessage: 'Select guests'
  },
  datesPrompt: {
    id: `${scope}.dates.prompt`,
    defaultMessage: 'Select dates'
  }
});
