import React  from 'react';
import { compose } from 'redux';
import GuestsPicker from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPicker/component';
import { FILTER_NUMBER_TYPE_COUNTER } from '@rentivo/gatsby-core/src/containers/SearchProvider/constants';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';

GuestsPicker.defaultProps = {
  type: FILTER_NUMBER_TYPE_COUNTER
};

const enhance = compose(
  withPricingInputs,
);

export default enhance(GuestsPicker);
