import React, { useRef } from 'react';
import classNames from 'classnames';
import GuestsPicker from '@rentivo/gatsby-core/src/components/ui/GuestsPicker';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPicker/messages';
import { StyledGuestsPicker } from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPicker/styles';
import { debounce } from 'lodash';
const debounceWait = 250;

const GuestsPickerComponent = ({className, isPetFriendly, maxPets, maxOccupancy, setGuests, dataConfig, guestsObj, type}) => {
  const {
    guests: guestsOptions,
    adults: adultsOptions,
    children: childrenOptions,
    infants: infantsOptions,
    pets: petsOptions
  } = dataConfig || {};

  let { min, max } = guestsOptions || {};
  max = (maxOccupancy) ? maxOccupancy : max;

  const { adults, children, infants, pets } = guestsObj;

  const onChange = ({guests, adults, children, infants, pets}) => {
    //console.log({guests, adults, children, infants, pets});
    setGuests({guests, adults, children, infants, pets});
  };

  const debouncedOnChange = useRef(debounce(data => onChange(data), debounceWait)).current;

  return (
    <StyledGuestsPicker className={classNames(className)}>
      <GuestsPicker
        options={{
          type,
          min,
          max,
          adults: adultsOptions,
          children: childrenOptions,
          infants: infantsOptions,
          pets: petsOptions
        }}
        adults={adults}
        children={children}
        infants={infants}
        pets={pets}
        onChange={debouncedOnChange}
      />
    </StyledGuestsPicker>
  );

};

export default GuestsPickerComponent;
