import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import InputPromptButton from '@rentivo/gatsby-core/src/containers/PricingProvider/components/InputPromptButton/component';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';

const enhance = compose(
  withPricingInputs
);

InputPromptButton.defaultProps = {
  className: '',
};

InputPromptButton.propTypes = {
  className: PropTypes.string,
};

export default enhance(InputPromptButton);
