import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingActions/messages';
import PropertyEnquiryButton from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryButton';
import PropertyEnquiryForm from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm';
import moment from 'moment';
import { DB_DATE_FORMAT } from '@rentivo/gatsby-core/src/constants/dates';
import { useForm } from 'react-hook-form';
import { clearObject } from '@rentivo/gatsby-core/src/utils/objects';

const PropertyEnquiryComponent = ({
    useModal,
    onEnquiryModalOpen,
    onEnquiryModalClose,
    showClearForm,
    buttonType,
    setDefaultValues,
    clearForm,
    loading,
    postForm,
    data,
    didInvalidate,
    formValues,
    error,
    startDate: startDatePricing,
    endDate: endDatePricing,
    ...rest
}) => {
  useEffect(() => {
    setDefaultValues({});
  }, []);

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    guests: 0,
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
    guestMessage: '',
    ...formValues,
    startDate: startDatePricing,
    endDate: endDatePricing,
    ...rest.guestsObj
  };

  const emptyValues = { ...clearObject(defaultValues), guests: 2, adults: 2, children: 0, infants: 0, pets: 0 };

  const initValue = (startDatePricing && endDatePricing) ? [moment(startDatePricing, DB_DATE_FORMAT), moment(endDatePricing, DB_DATE_FORMAT)] : [null, null];
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(initValue[0]);
  const [endDate, setEndDate] = useState(initValue[1]);

  const form = useForm({defaultValues});
  const { trigger, getValues } = form;

  useEffect(() => {
    if(startDatePricing !== startDate && endDatePricing !== endDate) {
      const dates = (startDatePricing && endDatePricing) ? [moment(startDatePricing, DB_DATE_FORMAT), moment(endDatePricing, DB_DATE_FORMAT)] : [null, null];
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  }, [startDatePricing, endDatePricing]);

  const mergeValues = (values) => {
    return {
      ...values,
      startDate: (startDate) ? startDate.format(DB_DATE_FORMAT) : null,
      endDate : (endDate) ? endDate.format(DB_DATE_FORMAT) : null
    };
  };

  // Submitted naturally.
  const onFinish = (values) => {
    e.preventDefault();
    postForm(mergeValues(values));
  };

  // Need to trigger form submit manually
  const handleOnFinish = async (e) => {
    e.preventDefault();
    try {
      const result = await trigger();
      if (result) {
        const values = await getValues();
        postForm(mergeValues(values));
      }
    } catch (e) {
      alert('Oops, something went wrong.');
    }
  };

  // Show form or button to form...
  const componentProps = { form, defaultValues, showClearForm, loading, startDate, emptyValues, clearForm, endDate, setStartDate, setEndDate, didInvalidate, error, data };



  return (
    <>
      {useModal ? (
        <PropertyEnquiryButton
          button={{block: true, type: buttonType}}
          buttonLabel={<FormattedMessage {...messages.buttonEnquireLabel}/>}
          onOk={handleOnFinish}
          visible={visible}
          onVisibleChange={() => {
            setVisible(!visible);
            if(!visible) {
              onEnquiryModalOpen();
            } else {
              onEnquiryModalClose();
            }
          }}
          componentProps={componentProps}
        />
      ) : (
        <PropertyEnquiryForm
          onFinish={onFinish}
          showSubmit={true}
          {...componentProps}
        />
      )}
    </>
  )
};

export default PropertyEnquiryComponent;
