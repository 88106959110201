import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.AvailabilityPickerPopover';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Select your dates'
  },
  label: {
    id: `${scope}.label`,
    defaultMessage: 'Select your dates'
  },
  setLabel: {
    id: `${scope}.setLabel`,
    defaultMessage: '{startDate} — {endDate}'
  },
  prompt: {
    id: `${scope}.prompt`,
    defaultMessage: 'Please select your dates'
  }
});
