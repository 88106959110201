import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.GuestsPickerPopover';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Select your guests'
  },
  label: {
    id: `${scope}.label`,
    defaultMessage: 'Select your guests'
  },
  setLabel: {
    id: `${scope}.setLabel`,
    defaultMessage: '{guestsCount, plural, =0 {0 guests} one {1 guest} other {{guestsCount} guests}}{petsCount, plural, =0 {} one {, 1 pet} other {, {petsCount} pets}}'
  },
  prompt: {
    id: `${scope}.prompt`,
    defaultMessage: 'Please select your guests'
  }
});
