import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import AvailabilityPickerPopover from '@rentivo/gatsby-core/src/containers/PricingProvider/components/AvailabilityPickerPopover/component';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';

const enhance = compose(
  withPricingInputs
);

AvailabilityPickerPopover.defaultProps = {
  id: null,
  useRedux: true,
  title: null,
  block: true,
  className: 'pricing-popover',
  component: null,
  componentProps: {
    onChange: () => null,
    months: 1,
    inPopover: true
  },
  tooltip: false,
  tooltipPlacement: 'top',
  scrollTo: false
};

AvailabilityPickerPopover.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  block: PropTypes.bool,
  scrollTo: PropTypes.bool,
  tooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.element,
  componentProps: PropTypes.object
};

export default enhance(AvailabilityPickerPopover);
