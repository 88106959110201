import React from 'react';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import cdnProps from '@rentivo/gatsby-core/src/cdn/propertyThumb.cdn';
import { faMapPin } from '@fortawesome/pro-regular-svg-icons/faMapPin';
import { Flex, Heading, Text, Box } from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const PropertySummaryComponent = ({featuredImage, headline, address, ...rest}) => {
  return (
    <Flex
      direction="row"
      align="center"
      pt={2}
      pb={2}
      {...rest}
    >
      {featuredImage && featuredImage.uri && (
        <Box
          w="75px"
          h="75px"
          flex="none"
          overflow="hidden"
          borderRadius="50%"
          mr={2}
          css={{
            'img': {
              width: '75px',
              height: '75px'
            }
          }}
        >
          <CdnImage uri={featuredImage.uri} alt={headline} {...cdnProps} width={75}/>
        </Box>
      )}
      <Box>
        <Heading as="h4">{headline}</Heading>
        <Text mb={0} size="sm"><AwesomeIcon icon={faMapPin} mr={1}/> {address}</Text>
      </Box>
    </Flex>
  );
};

export default PropertySummaryComponent;

