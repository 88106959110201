import PropertyEnquiry from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/component';
import { compose } from 'redux';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';
import {
  clearForm, onEnquiryModalClose, onEnquiryModalOpen,
  setDefaultValues,
  startSetForm
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/actions';
import { createSelector } from 'reselect';
import {
  selectPropertyEnquiryData, selectPropertyEnquiryDidInvalidate, selectPropertyEnquiryError, selectPropertyEnquiryForm,
  selectPropertyEnquiryIsFetching
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/selectors';
import { connect } from 'react-redux';
import { selectPropertyId } from '@rentivo/gatsby-core/src/selectors/property';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';

const propertySelector = createSelector(
  selectPropertyId,
  (providerPropertyId) => ({
    providerPropertyId
  })
);

const mapStateToProps = createSelector(
  selectPropertyEnquiryData,
  selectPropertyEnquiryIsFetching,
  selectPropertyEnquiryDidInvalidate,
  selectPropertyEnquiryError,
  selectPropertyEnquiryForm,
  (data, loading, didInvalidate, error, formValues) => ({
    data,
    loading,
    didInvalidate,
    error,
    formValues
  })
);

const mapDispatchToProps = (dispatch, { propertyId, providerPropertyId }) => {
  const usePropertyId = (propertyId) ? propertyId : providerPropertyId;
  return {
    setDefaultValues: (form) => dispatch(setDefaultValues(form, usePropertyId)),
    postForm: (values) => dispatch(startSetForm(values)),
    clearForm: () => dispatch(clearForm()),
    onEnquiryModalClose: () => dispatch(onEnquiryModalClose()),
    onEnquiryModalOpen: () => dispatch(onEnquiryModalOpen())
  };
};

const enhance = compose(
  withPricingInputs,
  withProperty(propertySelector),
  connect(mapStateToProps, mapDispatchToProps)
);

PropertyEnquiry.defaultProps = {
  useModal: true,
  buttonType: 'default',
  showClearForm: true
};

export default enhance(PropertyEnquiry);
