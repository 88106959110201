import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectRoutePathsConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { makeSelectPricingRoles } from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { startAddPricingToCart, } from '@rentivo/gatsby-core/src/containers/PricingProvider/actions';
import { makeSelectLocaleNavigate } from '@rentivo/gatsby-core/src/containers/App/selectors';
import { withCommerce } from '@rentivo/gatsby-core/src/context/CommerceContext';
import isEmpty from 'lodash/isEmpty';

const withPricingActions = WrappedComponent => {

  const withPricingActionsComponent = (props) => {
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state, { commerce }) => createSelector(
    makeSelectPricingRoles,
    makeSelectLocaleNavigate,
    selectRoutePathsConfig,
    (pricingRoles, localeNavigate, routePaths) => ({
      pricingRoles,
      navigateToCheckout: () => localeNavigate(routePaths.ROUTE_BASE_CHECKOUT),
      inCheckout: !isEmpty(commerce)
    })
  );

  const mapDispatchToProps = (dispatch) => ({
    addPricingToCart: () => dispatch(startAddPricingToCart())
  });

  const enhance = compose(
    withCommerce(),
    connect(mapStateToProps, mapDispatchToProps)
  );

  return enhance(withPricingActionsComponent);

};

export default withPricingActions;
