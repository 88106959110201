import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PropertyEnquiry';

export default defineMessages({
  sendEnquiryButtonLabel: {
    id: `${scope}.sendEnquiryButton.label`,
    defaultMessage: 'Send enquiry'
  }
});
