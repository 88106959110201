import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import PropertySummary from '@rentivo/gatsby-core/src/components/property/PropertySummary/component';
import {
  makeSelectPropertyHeadline,
  selectPropertyAddressCity, selectPropertyMedia,
  selectPropertyPhotoFeaturedImageFromMedia
} from '@rentivo/gatsby-core/src/selectors/property';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';

const propertySelector = createSelector(
  selectPropertyMedia,
  makeSelectPropertyHeadline,
  selectPropertyAddressCity,
  (media, headline, address) => ({
    featuredImage: selectPropertyPhotoFeaturedImageFromMedia(media),
    headline,
    address
  })
);

const enhance = compose(
  withProperty(propertySelector)
);

PropertySummary.defaultProps = {
  className: '',
};

PropertySummary.propTypes = {
  className: PropTypes.string,
};

export default enhance(PropertySummary);
