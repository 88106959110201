import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingActions/messages';
import { Button } from '@rentivo/gatsby-core/index';
import SearchEnquiry from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry';
import { VStack } from '@chakra-ui/react';

const PricingActionsComponent = ({className, pricingRoles, addPricingToCart, navigateToCheckout, areGuestsSelected, areDatesSelected, styleProps = {}, ...rest}) => {
  const {
    canMakeReservation,
    canSendEnquiry,
    canRequestToBook,
    isInstantBook,
    isAvailable
  } = pricingRoles;

  if(!canMakeReservation && !isAvailable && !canSendEnquiry) return null;

  if((!areGuestsSelected || !areDatesSelected) && !canSendEnquiry) return null;

  return (
    <VStack mt={3} spacing={2} className={className} {...styleProps} css={{'> div': { width: '100%'}}}>
      {areGuestsSelected && areDatesSelected && (
        <>
          {canMakeReservation && (
            <Button type="primary" block useDebounce onClick={(e) => {
              addPricingToCart();
              navigateToCheckout();
            }}>

              { isInstantBook && (
                <FormattedMessage {...messages.buttonInstantBookLabel} />
              )}

              { canRequestToBook && (
                <FormattedMessage {...messages.buttonRequestToBookLabel} />
              )}

            </Button>
          )}
        </>
      )}

      {canSendEnquiry && (
        <SearchEnquiry buttonType={(!canMakeReservation && canSendEnquiry) ? 'primary' : 'default'}/>
      )}
    </VStack>
  );
};

export default PricingActionsComponent;
