import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingDisplay/messages';
import Price from '@rentivo/gatsby-core/src/components/generic/Price';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import LineItem from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingDisplay/components/LineItem';
import { newlinesToBr } from '@rentivo/gatsby-core/src/utils/strings';
import {
  Heading,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  VStack,
  chakra,
  useTheme
} from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import { useLycanFormattedMessage } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage';

const useTotalStyleProps = (isMainTotal = true, increaseFontSize = true) => {
  const { fontWeights, fontSizes } = useTheme();
  return {
    fontWeight: isMainTotal ? fontWeights.bold : fontWeights.normal,
    color: 'text',
    fontSize: increaseFontSize ? isMainTotal ? fontSizes.xl : '1rem' : '1rem',
    css: {
      '.price-number': {
        fontWeight: isMainTotal ? fontWeights.bold : fontWeights.normal,
        fontSize: increaseFontSize ? isMainTotal ? fontSizes.xl : '1rem' : '1rem'
      }
    }
  }
};

export const Adjustment = ({adjustment: { calculationOperand, description, identifier, amount }, type, currency, getExchangedCurrency, currentCurrency}) => {
  const { formatNumber } = useIntl();
  const getLycanMessage = useLycanFormattedMessage({useFriendlyName: false});
  const lycanTitle = getLycanMessage(identifier, null);
  const lycanDescription = getLycanMessage(`${identifier}_DESCRIPTION`, null);
  let title = (lycanTitle) ? lycanTitle : description;
  const infoDescription = (lycanDescription !== `${identifier}_DESCRIPTION`) ? lycanDescription : null;

  if(type === 'after') {
    let price = getExchangedCurrency(amount, 2, currency);
    price = formatNumber(price, {currency: currency, useGrouping: true, style: 'currency', currencyDisplay: 'symbol' });
    return <chakra.div fontSize="sm"><FormattedMessage {...messages.priceDisplayOnArrivalItem} values={{price, title}}/></chakra.div>
  }

  const extraProps = {};

  if(calculationOperand === 'subtraction' || amount < 0) {
    extraProps.color = 'green.500';
    extraProps.bg = 'green.50';
    extraProps.py = '2px';
    extraProps.px = '10px';
    extraProps.fontWeight = 'medium';
  }

  return <LineItem
    title={title}
    price={amount}
    currency={currency}
    type={type}
    infoDescription={infoDescription}
    {...extraProps}
  />;
};

const PricingDisplayComponent = ({currentCurrency, getExchangedCurrency, data, error, display, inCheckout, className, alwaysShowBeforeArrivalFees, alwaysShowAfterArrivalFees}) => {
  const {
    isAvailable,
    isPriced,
    basePrice,
    total,
    currency,
    dueNow,
    noNights,
    perNightPrice,
    chargeCurrency,
    chargeAmountDue,
    chargeAmountExchangeRate,
    beforeArrivalAdjustments,
    afterArrivalAdjustments,
    isRequestToBook,
    isInstantBook,
    customMessages
  } = display;

  const mainTotalStyleProps = useTotalStyleProps(true);
  const totalStyleProps = useTotalStyleProps(false);
  const mainTotalNormalStyleProps = useTotalStyleProps(true, false);

  if(error || !data || !isAvailable) return null;
  const [beforeArrivalFeesOpen, setBeforeArrivalFeesOpen] = useState(false);
  const [arrivalFeesOpen, setArrivalFeesOpen] = useState(false);

  const {
    beforeArrivalAdjustmentItemsAdd,
    beforeArrivalAdjustmentItemsAddTotal,
    beforeArrivalAdjustmentItemsSub,
    afterArrivalAdjustmentItemsAddTotal,
    afterArrivalAdjustmentItems,
    customPricingMessages,
    totalWithoutDiscounts
  } = useMemo(() => {
    const beforeArrivalAdjustmentItemsAdd = beforeArrivalAdjustments.filter(a => a.calculationOperand !== 'subtraction' && a.amount > 0).map((adjustment, i) => <Adjustment key={i} currentCurrency={currency} getExchangedCurrency={getExchangedCurrency} adjustment={adjustment} type="before" {...display}/>);
    const beforeArrivalAdjustmentItemsAddTotal = beforeArrivalAdjustments.filter(a => a.calculationOperand !== 'subtraction' && a.amount > 0).reduce((acc, item) => acc + item.amount, 0);
    const beforeArrivalAdjustmentItemsSub = beforeArrivalAdjustments.filter(a => a.calculationOperand === 'subtraction' || a.amount < 0).map((adjustment, i) => <Adjustment key={i} currentCurrency={currency} getExchangedCurrency={getExchangedCurrency} adjustment={adjustment} type="before" {...display}/>);
    const afterArrivalAdjustmentItemsAddTotal = afterArrivalAdjustments.reduce((acc, item) => acc + item.amount, 0);
    const afterArrivalAdjustmentItems = afterArrivalAdjustments.map((adjustment, i) => <Adjustment key={i} currentCurrency={currency} getExchangedCurrency={getExchangedCurrency} adjustment={adjustment} type="after" {...display}/>);
    const customPricingMessages = customMessages.map((item, i) => (<chakra.div key={i}><AwesomeIcon mr={1} icon={faInfoCircle}/> <span dangerouslySetInnerHTML={{__html: newlinesToBr(item)}}/></chakra.div>));
    const totalWithoutDiscounts = parseFloat(total + beforeArrivalAdjustmentItemsAddTotal);

    return {
      beforeArrivalAdjustmentItemsAdd,
      beforeArrivalAdjustmentItemsAddTotal,
      beforeArrivalAdjustmentItemsSub,
      afterArrivalAdjustmentItemsAddTotal,
      afterArrivalAdjustmentItems,
      customPricingMessages,
      totalWithoutDiscounts
    }
  }, [beforeArrivalAdjustments, afterArrivalAdjustments, customMessages, currentCurrency, getExchangedCurrency, display]);

  return (
    <Box position="relative" zIndex={1} className={className} mt={2}>
      {(isPriced) && (
        <>
          <VStack spacing={1}>
            <LineItem
              title={
                <FormattedMessage
                  {...messages.nightsPriceLabel}
                  values={{itemCount: noNights, price: <Price price={perNightPrice} currency={currency} period={""} fractionDigits={2} periodSeparator={""}/>}}
                />
              }
              price={basePrice}
              currency={currency}
              type="nights"
            />

            {(beforeArrivalAdjustmentItemsAdd !== undefined && beforeArrivalAdjustmentItemsAdd !== null && beforeArrivalAdjustmentItemsAdd.length > 0) && (

              <>
                {alwaysShowBeforeArrivalFees ? (
                  <>
                    {beforeArrivalAdjustmentItemsAdd}
                  </>
                ) : (
                  <>
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Box width="100%">
                          <LineItem
                            onClick={() => setBeforeArrivalFeesOpen(true)}
                            title={
                              <>
                                <AwesomeIcon icon={faPlus} mr={3}/>
                                <FormattedMessage
                                  {...messages.feesLabel}
                                  values={{
                                    itemCount: beforeArrivalAdjustmentItemsAdd.length,
                                    price: <Price price={perNightPrice} currency={currency} period={""} fractionDigits={2} periodSeparator={""}/>,
                                    small: (children) => <small>{children}</small>
                                  }}
                                />
                              </>
                            }
                            price={beforeArrivalAdjustmentItemsAddTotal}
                            currency={currency}
                            type="nights"
                            _hover={{
                              color: 'primary.500',
                              cursor: 'pointer'
                            }}
                          />
                        </Box>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverHeader fontWeight="semibold"><FormattedMessage {...messages.feesLabel}/></PopoverHeader>
                        <PopoverBody >
                          <VStack spacing={1} minW="280px">
                            {beforeArrivalAdjustmentItemsAdd}
                            {beforeArrivalAdjustmentItemsAddTotal && (<LineItem
                              title={<FormattedMessage {...messages.totalLabel}/>}
                              price={beforeArrivalAdjustmentItemsAddTotal}
                              currency={currency}
                              {...mainTotalNormalStyleProps}
                            />)}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </>
                )}
              </>
            )}

            {(beforeArrivalAdjustmentItemsSub && beforeArrivalAdjustmentItemsSub.length > 0) && (
              <>
                {beforeArrivalAdjustmentItemsSub}
              </>
            )}

          </VStack>

          <Box
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="gray.200"
            bg="white"
            px={4}
            py={3}
            mx={-4}
            my={2}
          >
            {((beforeArrivalAdjustmentItemsSub && beforeArrivalAdjustmentItemsSub.length > 0) && (totalWithoutDiscounts > total)) && (
              <LineItem
                //title={<FormattedMessage{...messages.percDiscountLabel} values={{discount_perc: ((totalWithoutDiscounts - total) / totalWithoutDiscounts * 100).toFixed(0)}}/>}
                price={totalWithoutDiscounts}
                currency={currency}
                type="total"
                {...totalStyleProps}
                marginBottom="-4px"
                fontSize="0.9rem"
                color="green.500"
                fontWeight="medium"
                css={{
                  '.price-number': {
                    color: "red",
                    textDecoration: "line-through"
                  }
                }}
              />
            )}

            <LineItem
              title={<FormattedMessage{...messages.totalLabel}/>}
              price={total}
              currency={currency}
              type="total"
              {...mainTotalStyleProps}
            />

            {isInstantBook && (
              <LineItem
                title={<FormattedMessage{...messages.payableAmountDueLabel}/>}
                price={dueNow}
                currency={currency}
                type="due"
                {...totalStyleProps}
              />
            )}
          </Box>

          {(afterArrivalAdjustmentItems.length > 0) && (
            <Box>

              {(inCheckout || alwaysShowAfterArrivalFees) ? (
                <VStack spacing={1}>
                  <Heading as="h5" w="100%"><FormattedMessage {...messages.priceDisplayOnArrivalTitle}/></Heading>
                  {afterArrivalAdjustmentItems}
                </VStack>
              ) : (
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Box width="100%">
                      <LineItem
                        onClick={() => setArrivalFeesOpen(true)}
                        title={
                          <>
                            <AwesomeIcon icon={faPlus} mr={3}/>
                            <FormattedMessage {...messages.priceDisplayOnArrivalTitle}/>
                          </>
                        }
                        price={afterArrivalAdjustmentItemsAddTotal}
                        currency={currency}
                        _hover={{
                          color: 'primary.500',
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold"><FormattedMessage {...messages.priceDisplayOnArrivalTitle}/></PopoverHeader>
                    <PopoverBody >
                      <VStack spacing={1} minW="280px">
                        {afterArrivalAdjustmentItems}
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </Box>
          )}
        </>
      )}

      {(isAvailable && (customPricingMessages.length > 0 || isPriced === false)) && (
        <VStack
          fontSize="sm"
          color="textLight"
          spacing={2}
          mt={3}
        >

          {isPriced === false && <chakra.div><AwesomeIcon mr={1} icon={faCheck} color="green.400"/> <FormattedMessage {...messages.priceDisplayOnAvailableNoPriceText}/></chakra.div>}

          {customPricingMessages}
        </VStack>
      )}

    </Box>
  );
};

export default PricingDisplayComponent;
