import React from 'react';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingFixedInputs/messages';
import { Stack, chakra } from '@chakra-ui/react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const PricingFixedInputsComponent = ({guestsObj: { guests, pets }, startDate, endDate, ...rest}) => {
  const { formatDate } = useIntl();
  startDate = (startDate) ? formatDate(startDate, {year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC'}) : null;
  endDate = (endDate) ? formatDate(endDate, {year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC'}) : null;

  return (
    <Stack spacing={2} pt={2} pb={2} {...rest}>
      <chakra.div><AwesomeIcon icon={faUsers} mr={2}/> <FormattedMessage {...messages.guestsText} values={{guestsCount: guests, petsCount: pets}} /></chakra.div>
      <chakra.div><AwesomeIcon icon={faCalendar} mr={2}/> <FormattedMessage {...messages.datesText} values={{startDate, endDate}} /></chakra.div>
    </Stack>
  );
};

export default PricingFixedInputsComponent;
