import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PricingFixedInputs';

export default defineMessages({
  guestsText: {
    id: `${scope}.guests.text`,
    defaultMessage: '{guestsCount, plural, =0 {0 guests} one {1 guest} other {{guestsCount} guests}}{petsCount, plural, =0 {} one {, 1 pet} other {, {petsCount} pets}}'
  },
  datesText: {
    id: `${scope}.dates.text`,
    defaultMessage: '{startDate} — {endDate}'
  }
});
