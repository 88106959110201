import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectPricingData,
  selectPricingError,
  selectPricingIsFetching,
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { withCommerce } from '@rentivo/gatsby-core/src/context/CommerceContext';
import isEmpty from 'lodash/isEmpty';
import {
  selectPropertyPricingAlwaysShowAfterArrivalFees,
  selectPropertyPricingAlwaysShowBeforeArrivalFees
} from '@rentivo/gatsby-core/src/selectors/siteConfig';

const withPricingData = WrappedComponent => {

  const withPricingDataComponent = (props) => {
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state, { commerce }) => createSelector(
    selectPricingData,
    selectPricingIsFetching,
    selectPricingError,
    selectPropertyPricingAlwaysShowBeforeArrivalFees,
    selectPropertyPricingAlwaysShowAfterArrivalFees,
    (data, loading, error, alwaysShowBeforeArrivalFees, alwaysShowAfterArrivalFees) => ({
      data,
      loading,
      error,
      inCheckout: !isEmpty(commerce),
      alwaysShowBeforeArrivalFees,
      alwaysShowAfterArrivalFees
    })
  );

  const enhance = compose(
    withCommerce(),
    connect(mapStateToProps)
  );

  return enhance(withPricingDataComponent);

};

export default withPricingData;
