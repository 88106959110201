import PropertyEnquiryButton from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryButton/component';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryButton/messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import loadable from '@loadable/component';
const Component = loadable(() => import('@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm'));

export const modalProps = {
  width: 640,
  destroyOnClose: true,
  confirmLoading: false
};

PropertyEnquiryButton.defaultProps = {
  title: <FormattedMessage {...messages.buttonEnquireTitle} />,
  buttonLabel: <FormattedMessage {...messages.buttonEnquireLabel} />,
  closeButtonLabel: <FormattedMessage {...messages.cancelButtonLabel} />,
  okButtonLabel: <FormattedMessage {...messages.okButtonLabel}/>,
  useModal: true,
  modal: {
    width: 640,
    destroyOnClose: false
  },
  showOkButton: true,
  showCloseButton: false,
  component: Component,
  componentProps: {},
  button: {
    block: false
  }
};

export default PropertyEnquiryButton;
