import React from 'react';
import { Button } from '@rentivo/gatsby-core/index';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/InputPromptButton/messages';
import { chakra } from '@chakra-ui/react';

const InputPromptButtonComponent = ({areDatesSelected, areGuestsSelected, setDatesPopoverOpen, setGuestsPopoverOpen, styleProps = {}, ...rest}) => {
  if(areGuestsSelected && areDatesSelected) return null;
  const handleGuests = (areDatesSelected && !areGuestsSelected);

  const handleOnClick = (e) => {
    e.preventDefault();
    if(handleGuests) setGuestsPopoverOpen(true);
    if(!handleGuests) setDatesPopoverOpen(true);
  };

  return (
    <chakra.div {...styleProps}>
      <Button onClick={handleOnClick} block type="primary">{handleGuests ? <FormattedMessage {...messages.guestsPrompt}/> : <FormattedMessage {...messages.datesPrompt}/>}</Button>
    </chakra.div>
  );
};

export default InputPromptButtonComponent;

