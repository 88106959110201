import React from 'react';
import Price from '@rentivo/gatsby-core/src/components/property/Price';
import PricingDisplay from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingDisplay';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';
import PricingErrors from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingErrors';
import PricingActions from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingActions';
import PricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingInputs';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingActions/messages';
import PropertyDiscounts from '@rentivo/gatsby-core/src/components/property/PropertyDiscounts';
import { Box, chakra, Text } from '@chakra-ui/react';
import PropertySummary from '@rentivo/gatsby-core/src/components/property/PropertySummary';
import PricingFixedInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingFixedInputs';

const paddingSides = {
  pl: 4,
  pr: 4
};

// TODO: Update Price to display nightly rrate.

const PricingWidgetComponent = ({components, ...rest}) => {
  const { data, error, errors, loading, inCheckout, showHeader, isCheckoutInstantBook, isCheckoutRequestToBook, areGuestsSelected, areDatesSelected, display } = rest;
  const { isAvailable } = data || {};
  const { perNightPrice } = display || {};
  const isSomeError = ((errors.length > 0) || error || (isAvailable === false));
  return (
    <chakra.div position="relative">
      {(!inCheckout) && (
        <PropertyDiscounts
          popoverPlacement="bottom-end"
          zIndex={5}
          type="white"
          boxShadow="lg"
          wrapperStyleProps={{
            position: 'absolute',
            top: { base: '-7px', md: '-21px' },
            right: { base: '-4px', md: '-10px' },
            zIndex: 5
          }}
        />
      )}
      <Box
        position="relative"
        boxShadow="xl"
        borderRadius="lg"
        bg="white"
        overflow="hidden"
        mb={4}
      >
        {showHeader && (
          <Box
            pt={2}
            pb={2}
            {...paddingSides}
            color="white"
            bg="primary.500"
            css={{
              '.period, .price-prefix': {
                color: 'rgba(255, 255, 255, 0.66)'
              }
            }}
          >
            {(!inCheckout) ? (
              <Price forcePerNight={perNightPrice}/>
            ) : (
              <Text as="strong" fontWeight="semibold">
                {isCheckoutInstantBook && <FormattedMessage {...messages.buttonInstantBookLabel}/>}
                {isCheckoutRequestToBook && <FormattedMessage {...messages.buttonRequestToBookLabel}/>}
              </Text>
            )}
          </Box>
        )}

        <Box
          pb={4}
          mt={2}
          position="relative"
          {...paddingSides}
        >
          {loading && <Loader/>}

          {(!inCheckout) ? (
            <PricingInputs/>
          ) : (
            <>
              <PropertySummary
                borderBottom="1px solid"
                borderColor="gray.200"
                pb={4}
              />
              <PricingFixedInputs
                borderBottom="1px solid"
                borderColor="gray.300"
              />
            </>
          )}

          {(areGuestsSelected && areDatesSelected) && (
            <>
              {(data || isSomeError) && (
                <Box pt={2}>
                  {(data && !isSomeError) && <PricingDisplay {...rest}/>}
                  {(errors.length > 0 || isSomeError) && <PricingErrors {...rest} styleProps={{mt: 2}}/>}
                </Box>
              )}
            </>
          )}
          {(!inCheckout) && (
            <PricingActions {...rest}/>
          )}
        </Box>

      </Box>
    </chakra.div>
  );
};

export default PricingWidgetComponent;
