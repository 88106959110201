import React from 'react';
import Price from '@rentivo/gatsby-core/src/components/generic/Price';
import Info from '@rentivo/gatsby-core/src/components/ui/Info';
import { chakra, Flex, useTheme } from '@chakra-ui/react';

const LineItem = ({title, infoDescription, price, currency, type, className, ...rest}) => {
  const { fontWeights, fontSizes } = useTheme();
  return (
    <Flex
      width="100%"
      direction="row"
      justify="space-between"
      lineHeight="tall"
      className={className}
      color="textLight"
      fontSize="sm"
      css={{
        '.price-number': {
          fontWeight: fontWeights.normal,
          fontSize: fontSizes.sm
        }
      }}
      {...rest}
    >
      <chakra.div>{title} {infoDescription && <Info title={infoDescription}/>}</chakra.div>
      <Price
        price={price}
        currency={currency}
        period={""}
        fractionDigits={2}
        periodSeparator={""}
      />
    </Flex>
  )
};

export default LineItem;
