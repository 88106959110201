import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import PricingErrors from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingErrors/component';
import withPricingData from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingData';
import { createSelector } from 'reselect';
import {
  selectPricingDataErrors
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { connect } from 'react-redux';

const mapStateToProps = createSelector(
  selectPricingDataErrors,
  (errors) => ({
    errors
  })
);

const enhance = compose(
  withPricingData,
  connect(mapStateToProps)
);

PricingErrors.defaultProps = {
  className: '',
  inCheckout: false
};

PricingErrors.propTypes = {
  className: PropTypes.string,
};

export default enhance(PricingErrors);
