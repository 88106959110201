import React from 'react';
import { modalProps } from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryButton/index';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';

const PropertyEnquiryButtonComponent = (props) => {
  const { componentProps: { loading } } = props;
  return (
    <PopoverButton
      {...props}
      button={{
        block: true,
        textAlign: 'center'
      }}
      modal={{
        ...modalProps,
        confirmLoading: loading
      }}
    />
  )
};

export default PropertyEnquiryButtonComponent;
