import {
  CLEAR_PROPERTY_ENQUIRY_FORM, CLOSE_PROPERTY_ENQUIRY_MODAL, OPEN_PROPERTY_ENQUIRY_MODAL,
  POST_PROPERTY_ENQUIRY,
  SET_PROPERTY_ENQUIRY_DEFAULT_VALUES,
  SET_PROPERTY_ENQUIRY_FORM
} from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/constants';
import { debounce } from 'lodash';

export const setDefaultValues = (form, propertyId) => {
  return {
    type: SET_PROPERTY_ENQUIRY_DEFAULT_VALUES,
    form,
    propertyId
  };
};

export const startSetForm = (payload) => {
  return async (dispatch) => {
    await dispatch(setForm(payload));
    dispatch(postFormFunctionDebounced(payload));
  };
};

export const setForm = (payload = {}) => {
  return {
    type: SET_PROPERTY_ENQUIRY_FORM,
    payload
  };
};

export const clearForm = () => {
  return {
    type: CLEAR_PROPERTY_ENQUIRY_FORM
  };
};

export const postForm = () => {
  return {
    type: POST_PROPERTY_ENQUIRY
  };
};

export const onEnquiryModalOpen = () => {
  return {
    type: OPEN_PROPERTY_ENQUIRY_MODAL
  };
};

export const onEnquiryModalClose = () => {
  return {
    type: CLOSE_PROPERTY_ENQUIRY_MODAL
  };
};

const postFormFunction = debounce(dispatch => setTimeout(() => dispatch(postForm()), 50), 50);
const postFormFunctionDebounced = () => postFormFunction;
