import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingErrors/messages';
import { getMessageString } from '@rentivo/gatsby-core/src/containers/LycanFormattedMessage/utils';
import { Box, chakra, Alert, Text, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

const PriceError = ({type, message}) => {
  const { formatMessage } = useIntl();
  const errorType = (type === 'OTHER') ? 'TYPE_OTHER' : type;
  const lycanMessage = getMessageString(errorType, formatMessage, true);
  return (
    <Text as="span" fontSize="sm" mr={2}>
      {lycanMessage ? lycanMessage : message}.
    </Text>
  );
};

const PricingErrorsComponent = ({data, errors, error, className, styleProps = {}}) => {
  const { isAvailable } = data || {};
  const isSomeError = ((errors.length > 0) || error || (isAvailable === false));
  if(isSomeError === false) return null;

  return (
    <chakra.div className={className} {...styleProps}>
      <Alert status="error">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle><FormattedMessage {...messages.unavailableTitle}/></AlertTitle>
          {(errors && errors.length > 0) && (<AlertDescription display="block">
            {errors.map((pricingError, i) => (
              <PriceError key={i} {...pricingError}/>
            ))}
          </AlertDescription>)}
        </Box>
      </Alert>
    </chakra.div>
  );
};

/*

{errors && (
        <ul>
          {errors.map((pricingError, i) => (
            <PriceError key={i} {...pricingError}/>
          ))}
        </ul>
      )}

 */
/*
{errors && (
  <ul>
    {errors.map((pricingError, i) => (
      <PriceError key={i} {...pricingError}/>
    ))}
  </ul>
)}
 */

export default PricingErrorsComponent;
