import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import PricingActions from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingActions/component';
import withPricingActions from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingActions';

const enhance = compose(
  withPricingActions
);

PricingActions.defaultProps = {
  className: '',
  inCheckout: false
};

PricingActions.propTypes = {
  className: PropTypes.string,
};

export default enhance(PricingActions);
