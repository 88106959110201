import React from 'react';
import PropTypes from 'prop-types';
import PricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingInputs/component';

PricingInputs.defaultProps = {
  className: ''
};

PricingInputs.propTypes = {
  className: PropTypes.string,
};

export default PricingInputs;
