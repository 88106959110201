import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PricingDisplay';

export default defineMessages({
  nightsPriceLabel: {
    id: `${scope}.nightsPrice.label`,
    //defaultMessage: '{itemCount, plural, one {{price} x 1 night} other {{price} x {itemCount} nights}}',
    defaultMessage: '{itemCount} nights',
  },
  totalLabel: {
    id: `${scope}.total.label`,
    defaultMessage: 'Total',
  },
  percDiscountLabel: {
    id: `${scope}.perDiscount.label`,
    defaultMessage: '{discount_perc}% discount',
  },
  feesLabel: {
    id: `${scope}.fees.label`,
    defaultMessage: 'Additional fees',
  },
  hideFeesLabel: {
    id: `${scope}.hideFees.label`,
    defaultMessage: 'Hide fees <small>(click to hide fees)</small>',
  },
  payableAmountDueLabel: {
    id: `${scope}.payableAmountDue.label`,
    defaultMessage: 'Amount due today',
  },
  priceDisplayOnArrivalItem: {
    id: `${scope}.priceDisplayOnArrivalItem`,
    defaultMessage: 'There is a {title} of {price} which is payable on arrival.',
  },
  priceDisplayOnArrivalTitle: {
    id: `${scope}.priceDisplayOnArrivalTitle`,
    defaultMessage: 'Due at check-in',
  },
  priceDisplayOnArrivalOpenButtonText: {
    id: `${scope}.priceDisplayOnArrivalOpenButton.label`,
    defaultMessage: 'Show fees collected on arrival',
  },
  priceDisplayOnArrivalCloseButtonText: {
    id: `${scope}.priceDisplayOnArrivalCloseButton.label`,
    defaultMessage: 'Hide fees collected on arrival',
  },
  priceDisplayOnAvailableNoPriceText: {
    id: `${scope}.priceDisplayOnAvailableNoPrice.text`,
    defaultMessage: 'This property is available! For a quote, please use the button below to make an enquiry.',
  }
});
