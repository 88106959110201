import React, { useState } from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/AvailabilityPickerPopover/messages';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import loadable from '@loadable/component';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';
import { chakra, Tooltip } from '@chakra-ui/react';
import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';

const Component = loadable(() => import('@rentivo/gatsby-core/src/containers/PricingProvider/components/AvailabilityPicker'));

const AvailabilityPickerPopoverComponent = ({id, scrollTo, component, useRedux, tooltip, tooltipPlacement, datesPopoverOpen, setDatesPopoverOpen, block, title, componentProps, startDate, endDate, styleProps = {}, ...rest}) => {
  const { formatDate, formatMessage } = useIntl();
  const areDatesSelected = (startDate && endDate);
  const [visible, setVisible] = useState(false);

  startDate = (startDate) ? formatDate(startDate, {year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC'}) : null;
  endDate = (endDate) ? formatDate(endDate, {year: 'numeric', month: 'short', day: '2-digit', timeZone: 'UTC'}) : null;

  return (
    <chakra.div {...styleProps}>
      {title !== undefined && <>{title ? title : <Text as="label" display="block" size="sm" color="textLight" fontWeight="medium" mb={2}><FormattedMessage {...messages.title}/></Text>}</>}
      <ConditionalWrapper
        condition={tooltip}
        wrapper={(children) => <TouchTooltip zIndex={1029} isOpen={(!areDatesSelected && !datesPopoverOpen)} placement={tooltipPlacement} label={formatMessage(messages.prompt)}>{children}</TouchTooltip>}
      >
        <PopoverButton
          id={id}
          button={{
            block
          }}
          scrollTo={scrollTo}
          popoverWidth={319}
          popoverHeight={339}
          component={component ? component : Component}
          componentProps={componentProps}
          visible={useRedux ? datesPopoverOpen : visible}
          onVisibleChange={() => {
            if(useRedux) {
              setDatesPopoverOpen(!datesPopoverOpen);
            } else {
              setVisible(!visible);
            }
          }}
          buttonLabel={areDatesSelected ? <FormattedMessage {...messages.setLabel} values={{startDate, endDate}}/> : <FormattedMessage {...messages.label}/>}
        />
      </ConditionalWrapper>
    </chakra.div>
  );
};

export default AvailabilityPickerPopoverComponent;

