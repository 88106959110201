import React from 'react';
import { FormattedMessage } from 'react-intl';
import EnquiryForm from '@rentivo/gatsby-core/src/components/forms/EnquiryForm';
import Form from '@rentivo/gatsby-core/src/components/forms/Form';
import {
  Alert,
  AlertIcon,
  Box,
  Stack
} from '@chakra-ui/react';
import Button from '@rentivo/gatsby-core/src/components/ui/ChakraButton';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm/messages';
import parentMessages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/messages';
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader';

const PropertyEnquiryFormComponent = ({showSubmit, className, styleProps = {}, ...rest}) => {
  const { onFinish, loading, data, didInvalidate, error, form } = rest;
  return (
    <Form form={form} onSubmit={onFinish}>
      <Box className={className} {...styleProps}>
        {loading && <Loader/>}
        {((!loading) && (data || error)) && (
          <Stack spacing={2} mb={4}>
            {(data && !error) && (<Alert status="success"><AlertIcon /> <FormattedMessage {...messages.successText}/></Alert>)}
            {(error) && (<Alert status="error"><AlertIcon /> <FormattedMessage {...messages.errorText}/></Alert>)}
          </Stack>
        )}

        <EnquiryForm {...rest}/>

        {showSubmit && (
          <Button loading={loading} type="primary" htmlType="submit"><FormattedMessage {...parentMessages.sendEnquiryButtonLabel}/></Button>
        )}
      </Box>
    </Form>
  );
};

export default PropertyEnquiryFormComponent;
