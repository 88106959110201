import { defineMessages } from 'react-intl';

export const scope = 'containers.PricingProvider.PropertyEnquiry.PropertyEnquiryForm';

export default defineMessages({
  successText: {
    id: `${scope}.success.text`,
    defaultMessage: 'Your enquiry has successfully been sent. We will get back to you shortly.'
  },
  errorText: {
    id: `${scope}.error.text`,
    defaultMessage: 'Sorry, something has gone wrong. Please try again later.'
  }
});
