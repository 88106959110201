import React from 'react';
import AvailabilityPickerPopover
  from '@rentivo/gatsby-core/src/containers/PricingProvider/components/AvailabilityPickerPopover';
import GuestsPickerPopover from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPickerPopover';
import InputPromptButton from '@rentivo/gatsby-core/src/containers/PricingProvider/components/InputPromptButton';
import { Stack } from '@chakra-ui/react';

const PricingInputsComponent = ({props}) => {
  return (
    <Stack spacing={4} pt={4} {...props}>
      <AvailabilityPickerPopover tooltip={false} scrollTo={true}/>
      <GuestsPickerPopover tooltip={false} scrollTo={false}/>
      <InputPromptButton styleProps={{mt: 2}}/>
    </Stack>
  );
};

export default PricingInputsComponent;
