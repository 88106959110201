import React from 'react';
import PropertyEnquiryForm from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PropertyEnquiry/components/PropertyEnquiryForm/component';

PropertyEnquiryForm.defaultProps = {
  onFinish: () => null,
  form: null,
  loading: false,
  showSubmit: false
};

export default PropertyEnquiryForm;
