import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import PricingFixedInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingFixedInputs/component';
import withPricingInputs from '@rentivo/gatsby-core/src/containers/PricingProvider/components/withPricingInputs';

const enhance = compose(
  withPricingInputs
);

PricingFixedInputs.defaultProps = {
  className: ''
};

PricingFixedInputs.propTypes = {
  className: PropTypes.string,
};

export default enhance(PricingFixedInputs);
